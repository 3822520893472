<div class="modal-header">
	<h3 class="modal-title" translate="ATTENTION"></h3>
</div>

<div class="modal-body">
	<h4 class="confirm-title" [translate]="message"></h4>
	<h5 *ngIf="obj" [innerText]="obj.name"></h5>

	<div class="form-row">
		<div class="field-text">
			<label class="label" translate="ASSET_FROZEN"></label>
			<div class="field-help" translate="ASSET_FROZEN_HELP"></div>
		</div>
		<div class="field-editor datepicker">
			<div>
				<input
					name="freeze"
					class="fm-input"
					matInput
					#frozenDateInput="ngModel"
					[(ngModel)]="obj.frozenDate"
					[min]="frozenDateOptions.minDate"
					[max]="frozenDateOptions.maxDate"
					[matDatepicker]="frozenDate"
					(click)="frozenDate.open()"
					required="true"
					placeholder="{{ 'FROZEN_DATE' | translate }}" />
				<input-status class="input-status" [obj]="frozenDateInput"></input-status>
				<mat-datepicker #frozenDate></mat-datepicker>
			</div>
		</div>
	</div>

	<div class="confirm-message" translate="CONFIRM_CONTINUE"></div>
</div>

<div class="modal-footer">
	<button class="fm-button" [disabled]="!obj.frozenDate" (click)="ok()" translate="OK"></button>
	<button class="fm-button" (click)="cancel()" translate="CANCEL"></button>
</div>
