import { Component, Input, OnInit } from '@angular/core';

import { ApiService, GlobalService, LogService } from '@fm/services';
import { ModalSizes } from '@fmlib/enums';
import { GlobalStore, Tooltip } from '@fmlib/interfaces';
import { TooltipDialogComponent } from './tooltip-dialog/tooltip-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';

@Component({
	selector: 'tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent implements OnInit {
	@Input() type: string;
	@Input() prop: string;

	global: GlobalStore;

	showTooltip: boolean;
	tooltip: Tooltip;

	helpText: string;

	protected FM_DEVELOPER_URL = 'developer.footmarks.com';

	constructor(
		private dialog: MatDialog,
		private FM_Log: LogService,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.helpText = this.getHelpText();

		this.buildTooltip();
	}

	buildTooltip(): void {
		this.showTooltip = false;

		this.tooltip = this.global.tooltips.find((t: Tooltip) => {
			return t.objType === this.type && t.objProp === this.prop;
		});

		// if tooltip doesnt exist we create a new one
		if (!this.tooltip) {
			const tt = { objType: this.type, objProp: this.prop };
			this.FM_Log.info('generateTooltip', tt);
			this.FM_Api.connect('tooltips').post(tt, (res) => {
				this.global.tooltips.push(res);
			});
		}
		// if tooltip exist with published data
		else if (this.tooltip?.tooltip !== '') {
			this.showTooltip = true;
		}
	}

	getHelpText(): string {
		return this.type.toUpperCase() + '_' + this.prop.toUpperCase() + '_HELP';
	}

	async openTooltipEditor(): Promise<void> {
		const { TooltipDialogComponent } = await import(
			/* webpackPrefetch: true */
			'../tooltip/tooltip-dialog/tooltip-dialog.component'
		);

		const dialogRef = this.dialog.open(TooltipDialogComponent, {
			panelClass: ModalSizes.Basic,
			data: {
				tooltip: this.tooltip,
			},
		});

		dialogRef.afterClosed().subscribe((results) => {
			if (results) {
				// refresh tooltips
				this.FM_Api.get('tooltips', { per_page: 1000 })
					.pipe(
						tap((res) => {
							this.global.tooltips = res;
							this.buildTooltip();
						})
					)
					.subscribe();
			}
		});
	}

	viewLink(path: string): void {
		const url = new URL(path);

		if (url.hostname === this.FM_DEVELOPER_URL) {
			const myform = document.createElement('form');
			myform.method = 'POST';
			myform.action = 'https://developer.footmarks.com/password';
			myform.style.display = 'none';
			myform.append('Content-Type', 'application/x-www-form-urlencoded');

			const passwordInput = document.createElement('input');
			passwordInput.type = 'hidden';
			passwordInput.name = 'password';
			passwordInput.value = '&0k+{n:O28m,S1m';
			myform.appendChild(passwordInput);

			const redirectInput = document.createElement('input');
			redirectInput.type = 'hidden';
			redirectInput.name = 'redirect';
			redirectInput.value = url.pathname;
			myform.appendChild(redirectInput);

			document.body.appendChild(myform);
			myform.target = '_blank';
			myform.submit();
		} else {
			window.open(path, '_blank');
		}
	}
}
