import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { FilterListComponent } from '../filterlist/filterlist.component';

import { FilterComponent } from './filter.component';
import { FilterAttributesComponent } from './filter-attributes/filter-attributes.component';
import { FilterDatesComponent } from './filter-dates/filter-dates.component';
import { FilterGeocoordinatesComponent } from './filter-geocoordinates.component';
import { FilterInputComponent } from './filter-input.component';
import { FilterPartnersComponent } from './filter-partners/filter-partners.component';
import { FilterQuicksearchComponent } from './filter-quicksearch/filter-quicksearch.component';
import { FilterRangeComponent } from './filter-range/filter-range.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { FilterSettingsComponent } from './filter-settings.component';
import { FilterStatesComponent } from './filter-states/filter-states.component';
import { FilterStatusComponent } from './filter-status/filter-status.component';
import { FilterTagsComponent } from './filter-tags/filter-tags.component';
import { FilterToggleComponent } from './filter-toggle.component';
import { FilterTypeComponent } from './filter-type/filter-type.component';

const components = [
	FilterListComponent,
	FilterComponent,
	FilterGeocoordinatesComponent,
	FilterInputComponent,
	FilterRangeComponent,
	FilterSelectComponent,
	FilterSettingsComponent,
	FilterToggleComponent,
];

const standalone = [
	FilterAttributesComponent,
	FilterDatesComponent,
	FilterPartnersComponent,
	FilterQuicksearchComponent,
	FilterSearchComponent,
	FilterStatesComponent,
	FilterStatusComponent,
	FilterTagsComponent,
	FilterTypeComponent,
];

@NgModule({
	imports: [SharedModule, ...standalone],
	declarations: components,
	exports: [...components, ...standalone],
})
export class FiltersModule {}
