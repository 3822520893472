import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore, Partner } from '@fmlib/interfaces';

import { cloneDeep, isEqual, map, reject, sortBy } from 'lodash-es';
import { ProfileType } from '@fmlib/enums';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-partners',
	templateUrl: './filter-partners.component.html',
	styleUrls: ['./filter-partners.component.less'],
})
export class FilterPartnersComponent implements OnInit, OnChanges {
	@Input() filter;
	@Input() partners: Partner[];
	@Input() prop;
	@Input() useTo;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	global: GlobalStore;
	ProfileType = ProfileType;
	currentFilter;
	qs: string;
	list: Partner[];
	selected: Partner[];
	partnerList: any[];
	total: number;

	noResults: boolean;
	isLoading: boolean;
	typeahead: any;

	protected LIST_LIMIT = 10;

	constructor(
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.typeahead = {};

		this.selected = [];

		// COPY PARTNERS FROM GLOBAL
		/*
		if (this.useTo) {
			this.global.partners.to.forEach((item) => {
				if (item && item.company) {
					this.partnerList.push(item.company);
				}
			});
		} else {			
			this.global.partners.from.forEach((item) => {
				if (item && item.company) {
					this.partnerList.push(item.company);
				}
			});
		}
		*/

		if (!this.prop) {
			this.prop = 'id';
		}

		// LOAD INITIAL ITEMS
		this.initFilter();
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = cloneDeep(this.filter);
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		// CLEAR THE PROP
		if (this.selected.length === 0) {
			delete this.filter;
		} else {
			this.filter = map(this.selected, this.prop);
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);
			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	clearItem(item): void {
		const obj = {};
		obj[this.prop] = item[this.prop];
		this.selected = reject(this.selected, { id: item.id });
		this.runupdate();

		if (this.selected.length === 0) {
			// LOAD INITIAL ITEMS
			this.initFilter();
		}
	}

	selectAll(): void {
		this.filter = 'all';
		this.selected = [];

		// FILTER OUTPUT
		this.filterChange.emit(this.filter);
		this.update.emit();
	}

	filterClick(filter: Partner): void {
		const check = this.selected.some(({ id }) => id === filter.id);
		if (!check) {
			this.selected.push(filter);
		} else {
			this.selected = reject(this.selected, { id: filter.id });
		}

		this.runupdate();
	}

	// CHECK
	isActive(filter: Partner): boolean {
		const check = this.selected.some(({ id }) => id === filter.id);
		return check;
	}

	setItem(val: Partner): void {
		this.list = [];
		this.selected.push(val);
		this.qs = '';
		this.runupdate();
	}

	getList(val: string): any {
		this.isLoading = true;

		const params = {
			sort: 'companyName',
			per_page: this.LIST_LIMIT,
			select: 'name companyName ' + this.prop,
			populate: 'company',
			conditions: {
				partner: this.global.company.id,
			},
		};

		if (val && val !== undefined && val !== '') {
			params.conditions['companyName'] = {
				$regex: '\\b' + val,
				$options: 'i',
			};
		}

		//REMOVE EXISTING
		if (this.selected.length) {
			params.conditions[this.prop] = { $nin: map(this.selected, this.prop) };
		}

		this.FM_Api.connect('partners').query(params, (data, headers) => {
			this.list = data;
			this.isLoading = false;

			if (!this.total) {
				this.total = parseInt(headers.get('X-Records-Total'), 10);
			}
		});
	}

	updateList(): void {
		this.getList(this.qs);
	}

	filterList(list: any[], qs: string): any[] {
		let filtered = [];
		if (qs) {
			filtered = list.filter((obj) => {
				return obj.name.toLowerCase().indexOf(qs.toLowerCase()) !== -1;
			});
		} else {
			filtered = list;
		}

		return filtered;
	}

	clearFilter(): void {
		this.selected = [];
		this.qs = '';
	}

	initFilter(): void {
		this.list = sortBy(this.partners, 'name').slice(0, this.LIST_LIMIT);
		this.total = this.partners.length;

		if (this.filter) {
			this.list.forEach((item) => {
				const test = this.filter.includes(item.id);
				if (test) {
					this.selected.push(item);
				}
			});
		}
	}
}
