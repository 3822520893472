import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Asset } from '@fmlib/interfaces';
import { addDays, subDays } from 'date-fns';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [MatDatepickerModule, SharedModule],
	selector: 'confirm-freeze',
	templateUrl: './confirm-freeze-dialog.component.html',
	styleUrls: ['./confirm-freeze-dialog.component.less'],
})
export class ConfirmFreezeDialogComponent implements OnInit {
	message: string;
	obj: Asset;

	frozenDateOptions;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ConfirmFreezeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.message = this.data.message ? this.data.message : '';

		this.obj = this.data.obj;

		this.obj.frozenDate = new Date();

		this.frozenDateOptions = {
			minDate: subDays(new Date(), 20),
			maxDate: addDays(new Date(), 20),
		};
	}

	ok(): void {
		this.dialogRef.close(true);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
