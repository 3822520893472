import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// MATERIAL UI
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

import { AttributeDialogComponent } from '../attributes/attribute-dialog/attribute-dialog.component';
import { FmIconComponent } from './fm-icon.component';
import { FmLoaderComponent } from './fm-loader/fm-loader.component';
import { FmSelectComponent } from './fm-select.component';

import { FmTagsComponent } from './fm-tags/fm-tags.component';
import { FmToggleComponent } from './fm-toggle.component';
import { InputStatusComponent } from './input-status.component';
import { ListMessageComponent } from './list-message.component';
import { ListStatusComponent } from './list-status.component';

import { PrefixInputComponent } from './prefix-input/prefix-input.component';
import { ProfileImageComponent } from '../profile/profile-image/profile-image.component';

import { SharingInfoComponent } from './sharing-info/sharing-info.component';

import { TaxonomiesListComponent } from '../taxonomies/taxonomies-list.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

import { UpdatingMessageComponent } from './updating-message/updating.message.componet';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	// const ts = Math.floor(Date.now() / 1000);
	// return new TranslateHttpLoader(http, '/assets/languages/', '.json?v=' + ts);
	return new TranslateHttpLoader(http, '/assets/languages/', '.json');
}

import { Observable, shareReplay } from 'rxjs';

export class CachedHttpTranslationLoader implements TranslateLoader {
	cache$: Observable<any> = null;
	cachedLang: string = null;

	constructor(
		private http: HttpClient,
		public prefix: string = '/assets/languages/',
		public suffix: string = '.json'
	) {}

	/**
	 * Gets the translations from the server
	 */
	getTranslation(lang: string): Observable<any> {
		if (!this.cache$ || this.cachedLang !== lang) {
			// console.log('getTranslation fix cache');

			this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(shareReplay(1));
			this.cachedLang = lang;
		}
		return this.cache$;
	}
}

// DIRECTIVES
import { DirectiveModule } from '../../directives/directives.module';

// PIPES
import { PipeModule } from '../../pipes/pipe.module';
import { Base64ImagePipe } from 'projects/ui-lib/src/pipes/base64image.pipe';

import { EmptyNumberPipe, EmptyStringPipe, FmStatusComponent, SimpleDatePipe } from '@fmlib/lib';

const components = [
	AttributeDialogComponent,

	FmSelectComponent,
	FmTagsComponent,
	FmToggleComponent,
	InputStatusComponent,

	ListMessageComponent,
	ListStatusComponent,

	PrefixInputComponent,
	SharingInfoComponent,
	TaxonomiesListComponent,
	TooltipComponent,
	UpdatingMessageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		//
		InfiniteScrollDirective,
		//
		MatCheckboxModule,
		MatDialogModule,
		MatNativeDateModule,
		MatSnackBarModule,
		MatTooltipModule,

		TranslateModule.forRoot({
			defaultLanguage: 'en-US',
			loader: {
				provide: TranslateLoader,
				useClass: CachedHttpTranslationLoader,
				deps: [HttpClient],
			},
		}),

		// FM MODULES
		DirectiveModule,
		PipeModule,

		// LIB PIPES
		Base64ImagePipe,
		EmptyNumberPipe,
		EmptyStringPipe,
		SimpleDatePipe,

		// STANDALONE
		FmIconComponent,
		FmLoaderComponent,
		FmStatusComponent,

		ProfileImageComponent,
	],
	declarations: components,
	exports: [
		...components,
		//
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		InfiniteScrollDirective,
		MatCheckboxModule,
		MatDialogModule,
		MatNativeDateModule,
		MatSnackBarModule,
		MatTooltipModule,

		TranslateModule,
		DirectiveModule,
		PipeModule,

		// LIB PIPES
		Base64ImagePipe,
		EmptyNumberPipe,
		EmptyStringPipe,
		SimpleDatePipe,

		// STANDALONE
		FmIconComponent,
		FmLoaderComponent,
		FmStatusComponent,
		ProfileImageComponent,
	],
})
export class SharedModule {}
