import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	Renderer2,
} from '@angular/core';

@Component({
	selector: 'fm-filter',
	template: `<ng-content></ng-content>`,
	styleUrls: ['./filter.component.less'],
})
export class FilterComponent implements AfterViewInit {
	@Input() defaultOpen: boolean;
	@ContentChild('filter') filter;

	@HostListener('click', ['$event'])
	clickHandler(event): void {
		if (event.target.tagName === 'H3') {
			if (this.elementRef.nativeElement.classList.contains('active')) {
				this.closeFilter();
			} else {
				this.openFilter();
			}
		}
	}

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2
	) {}

	ngAfterViewInit(): void {
		if (this.defaultOpen) {
			this.openFilter();
		}
	}

	openFilter(): void {
		this.renderer.addClass(this.elementRef.nativeElement, 'active');

		if (this.filter?.open) {
			this.filter.open();
		}
	}

	closeFilter(): void {
		this.renderer.removeClass(this.elementRef.nativeElement, 'active');

		if (this.filter?.close) {
			this.filter.close();
		}
	}
}
